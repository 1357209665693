import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <div>       
      <iframe src="https://app.powerbi.com/reportEmbed?reportId=174211d5-1e27-48ae-b709-dd1da92acc67&autoAuth=true&ctid=24fe244f-890e-46ef-be2f-a5202976b7a5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
      frameborder="1" 
      marginheight="0" 
      marginwidth="0" 
      width="1425" 
      height="770"
      align = "left"
      allowFullScreen="true"
      >
      </iframe>
      </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
