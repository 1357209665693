import logo from './logo.svg';
import './App.css';

import React from 'react';

class App extends React.Component {
  render(){
    return (
      <div className="App">
        <title> Cloud Metering Dashboard </title>
        <img src={logo} />
      </div>
    );
  }
}

export default App;
